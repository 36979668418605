import employee from "@/api/human-resource/employee";
import { baseState, baseMutations } from "@/store/mixins";

import { isEmpty } from "lodash";

export const state = {
    ...baseState,
    positions: [],
    status: [],
    statusReason: [],
    shifts: [],
    pcDates: [],
    paymentTypes: [],
    nationalities: [],
    branches: [],
    provinces: [],
    levels: [],
    salary_levels: [],
    lineManager:[],
    probationEndDate: null
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_POSITION(state, positions) {
        state.positions = positions;
    },
    SET_STATUS(state, data) {
        state.status = data;
    },
    SET_STATUS_REASON(state, data) {
        state.statusReason = data;
    },
    SET_SHIFTS(state, data) {
        state.shifts = data;
    },
    SET_PC_DATES(state, data) {
        state.pcDates = data;
    },
    SET_PAYMENT_TYPES(state, data) {
        state.paymentTypes = data;
    },
    SET_NATIONALITIES(state, data) {
        state.nationalities = data;
    },
    SET_BRANCHES(state, data) {
        state.branches = data;
    },
    SET_PROVINCES(state, data) {
        state.provinces = data;
    },
    SET_LEVELS(state, data) {
        state.levels = data;
    },
    SET_SALARY_LEVELS(state, data) {
        state.salary_levels = data;
    },
    SET_LINE_MANAGER(state, data) {
        state.lineManager = data
    },
    SET_PROBATION_END_DATE(state, date) {
        state.probationEndDate = date;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return employee.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return employee.store(data);
    },

    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await employee.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },

    update(context, { id, data }) {
        return employee.update(id, data);
    },

    destroy(context, id) {
        return employee.destroy(id);
    },

    photoUpload(context, data) {
        return employee.photoUpload(data);
    },
    async getPosition(context, attributes = {}) {
        let response = await employee.position({ params: attributes });
        context.commit("SET_POSITION", response.data);
    },

    async getStatus(context, attributes = {}) {
        let response = await employee.status({ params: attributes });
        context.commit("SET_STATUS", response.data);
    },

    async getStatusReason(context, attributes = {}) {
        let response = await employee.statusReason({ params: attributes });
        context.commit("SET_STATUS_REASON", response.data);
    },

    async getShift(context, attributes = {}) {
        let response = await employee.shift({ params: attributes });
        context.commit("SET_SHIFTS", response.data);
    },

    async getPcDate(context, attributes = {}) {
        let response = await employee.pcDate({ params: attributes });
        context.commit("SET_PC_DATES", response.data);
    },

    async getPaymentType(context, attributes = {}) {
        let response = await employee.paymentType({ params: attributes });
        context.commit("SET_PAYMENT_TYPES", response.data);
    },
    async getNationality(context, attributes = {}) {
        let response = await employee.nationality({ params: attributes });
        context.commit("SET_NATIONALITIES", response.data);
    },
    async getLevel(context, attributes = {}) {
        let response = await employee.level({ params: attributes });
        context.commit("SET_LEVELS", response.levels);
        context.commit("SET_SALARY_LEVELS", response.salary_levels);
    },
    async getBranch(context, attributes = {}) {
        let response = await employee.branch({ params: attributes });
        context.commit("SET_BRANCHES", response.data);
    },
    getProbationEndDate(context, attributes = {}){
        return employee.probationEndDate({ params: attributes });
    },
    moveBranch(context, data) {
        return employee.moveBranch(data);
    },
    storeContract(context, data) {
        return employee.storeContract(data);
    },
    previewPdf(context, attributes = {}) {
        return employee.previewPdf({
            params: Object.assign(
                {
                    template: localStorage.getItem("langCode")
                },
                attributes
            )
        });
    },
    addVillage(context, data) {
        return employee.addVillage(data);
    },
    async getProvince(context) {
        let response = await employee.province();
        context.commit("SET_PROVINCES", response.data);
    },
    async getLineManager(context, attributes = {}) {
         const params = Object.assign(
            {
                perPage: context.state.pagination.perPage,
                filter: {
                    search: attributes.search,
                    status_id: 1
                }
            },
            attributes
        );
        return employee.lineManager({ params });
    },
    getDistrict(context, province_code) {
        if (!province_code) return;
        return employee.district(province_code);
    },
    getCommune(context, district_code) {
        if (!district_code) return;
        return employee.commune(district_code);
    },
    getVillage(context, commune_code) {
        if (!commune_code) return;
        return employee.village(commune_code);
    },
    documentType(context, data) {
        return employee.documentType(data);
    },
    removeFileUploaded(context, attributes) {
        return employee.removeFile({
            params: attributes
        })
    },

};
